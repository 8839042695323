.chat-header-main {
  display: flex;
  padding: 0.75rem 1.25rem;
  gap: 0.5rem;
}

.username-chat-status {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.chat-user-image .ant-avatar {
  background-color: #007cf0;
}

.username-chat {
  color: #1e1e1e;

  font-feature-settings: "clig" off, "liga" off;
  /* font-family: SF Pro Text; */
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.chat-user-image .ant-badge-dot {
  width: .7031rem;
  height: .7031rem;
}

.userstatus-chat {
  color: #7e7e7e;

  /* font-family: SF Pro Text; */
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.email-phone-chat {
  display: flex;
  align-items: center;

  padding: 0.44rem 0.94rem;
  border-top: 1px solid #e4e4e4;
  border-bottom: 1px solid #e4e4e4;
}

.email-chat {
  color: #3E3E3E;
  text-align: right;
  /* font-family: SF Pro Text; */
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  margin-left: 0.313rem;
  margin-right: 0.62rem;
  cursor: pointer;
}

.phonenumber-chat {
  color: #3e3e3e;
  /* font-family: SF Pro Text; */
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-left: 0.313rem;
}
