.attachment-container-msg {
    width: 100%;
    margin: .375rem 0 .375rem 0;
    position: relative;
    cursor: pointer;
}

.attachment-content-msg {
    width: 75%;
    display: flex;
    gap: 0.9375rem;
    border-radius: .25rem;
    border: .0625rem solid #E4E4E4;
    background: #FFF;
    padding: .75rem;
    align-items: center;
}

.attachment-content-info-msg {
    width: 90%;
}

.fileIcon-msg {
    height: 1.625rem;
}

.msg-time-stamp {
    padding-right: .625rem;
}

.attachment-content-info-msg>h3 {
    width: 90%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 0rem;
    padding-bottom: 0.125rem;
    font-size: .875rem;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #1E1E1E;
}

.attachment-content-info-msg>p {
    margin: 0rem;
    font-size: .875rem;
    font-weight: 400;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #3E3E3E;
}