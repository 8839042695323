.CommonTableContainer
  .ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-content
  > table {
  border-top: 0.0625rem solid #dddada;
}

.CommonTableContainer .ant-table.ant-table-bordered > .ant-table-container {
  border-left: 0.0625rem solid #dddada;
}

.CommonTableContainer
  .ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-content
  > table
  > thead
  > tr
  > th {
  font-size: 0.875rem;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #1e1e1e;
  padding: 0.9375rem 0.6875rem;
  border-right: none;
  border-bottom: none;
  background: #cfe7fd;
}

.CommonTableContainer .ant-table-column-sorters {
  display: flex;
  flex: auto;
  align-items: center;
  justify-content: flex-start;
}

.CommonTableContainer .ant-table-column-title {
  position: relative;
  z-index: 1;
  flex: none;
}

.CommonTableContainer .ant-table-column-sorter {
  margin-left: 0.625rem;
}

/* .CommonTableContainer .ant-checkbox-inner {
  width: 1.25rem;
  height: 1.25rem;
} */

.CommonTableContainer
  .ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-content
  > table
  > tbody
  > tr
  > td {
  vertical-align: top;
  padding: 0.6875rem 0.75rem;
  border-right: none;
  border-bottom: 0.0625rem solid #dddada;
  word-break: break-all;
}

.CommonTableContainer
  .ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-content
  > table
  > tbody
  > tr
  > td:last-child,
.CommonTableContainer
  .ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-content
  > table
  > thead
  > tr
  > th:last-child {
  border-right: 0.0625rem solid #dddada;
}

.CommonTableContainer .ant-table-tbody > tr.ant-table-row:hover > td,
.ant-table-tbody > tr > td.ant-table-cell-row-hover {
  background: #f9f9f9;
}

.CommonTableContainer .ant-table-tbody > tr.ant-table-row-selected > td {
  background: #f9f9f9;
}

.CommonTableContainer .ant-checkbox-checked .ant-checkbox-inner,
.CommonTableContainer .ant-checkbox-indeterminate .ant-checkbox-inner::after {
  background-color: #4054b2;
  border-color: #4054b2;
}

.CommonTableContainer .ant-checkbox-input:focus + .ant-checkbox-inner,
.CommonTableContainer .ant-checkbox-input:hover + .ant-checkbox-inner {
  border-color: #4054b2;
}
