.progress-bar {
  fill: none;

  stroke: #007cf0;

  stroke-width: 3;

  stroke-linecap: round;

  transform: rotate(-90deg);

  transform-origin: 50% 50%;

  transition: stroke-dashoffset 0.35s;
}

.circular-progress-bar-container {
  display: flex;

  justify-content: center;

  align-items: center;
}

.circular-progress-bar2 {
  width: 6.25rem;

  height: 6.25rem;

  position: relative;
}

.circular-progress-bar {
  width: 100%;

  height: 100%;

  position: absolute;

  top: 0;

  left: 0;

  transform: rotate(-90deg);

  transform-origin: 50% 50%;
}

.progress-bar-background {
  fill: none;

  stroke: #f4f4f4;

  stroke-width: 3;
}

.small-circle {
  width: 1.375rem;

  height: 1.375rem;

  border-radius: 50%;

  background-color: #007cf0;

  display: flex;

  justify-content: center;

  align-items: center;

  cursor: pointer;
}

.percentage-text {
  font-size: 0.5rem;

  color: #fff;
}

.CircularProgressTooltip .ant-tooltip-inner {
  font-size: 0.625rem;

  font-weight: normal;

  color: #fff;

  align-items: center;

  display: flex;
}

.small-circle-menu {
  width: 0.5rem !important;

  height: 0.5rem !important ;
}

@media screen and (max-width: 1000px) {
  .small-circle {
    width: 1.25rem;

    height: 1.25rem;
  }

  .percentage-text {
    font-size: 0.4375rem;

    font-weight: bold;

    color: #fff;
  }
}
