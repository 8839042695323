/* .CommonTableContainer .ant-table-column-sorter {
  color: #c4c4c4;
} */

/* .CommonTableContainer .ant-table-column-sorter-up.active,
.CommonTableContainer .ant-table-column-sorter-down.active {
  color: #4054b2;
} */

.TableHeaderTitleContainer {
  display: flex;
  justify-content: space-between;
}

.sortingContainer {
  position: relative;
}

.sortingContainer > img {
  cursor: pointer;
}

.sortingContent {
  position: absolute;
  top: 0.9375rem;
  right: 0rem;
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  padding: 0.625rem;
  border-radius: 0.25rem;
  border: solid 0.0625rem #e4e4e4;
  background-color: #fff;
  z-index: 10000;
}

.sortingContent > span {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.3125rem;
  min-width: max-content;
  font-size: 0.75rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #3e3e3e;
  cursor: pointer;
  margin-right: 1.0625rem;
}
