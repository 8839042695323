.alertNotificationAppWrapAdmin .ant-notification {
  margin-right: unset;
}

.Stages-alertAdmin {
  display: flex !important;
  justify-content: center !important;
  height: 3.75rem !important;
  width: 100vw !important;
  border-radius: 0 !important;
  max-width: unset !important;
  right: unset !important;
  padding: 0.625rem 1.5rem !important;
}

.Stages-alertAdmin
  .ant-notification-notice-with-icon
  .ant-notification-notice-message {
  color: #fff;
  margin-top: 0.5rem;
  margin-left: 2.188rem;
  font-size: 0.875rem;
  font-weight: 500;
}

.Stages-alertAdmin .ant-notification-notice-close {
  top: 0.938rem;
}

.Stages-alertAdmin .ant-notification-notice-with-icon {
  display: flex;
  align-items: center;
  justify-content: center;
}

.Stages-alertAdmin .ant-notification-notice-btn {
  margin-left: unset;
  margin-top: unset;
  margin-left: 2.688rem;
}

.Stages-alertAdmin .chatButton-MobileAdmin {
  color: #118936;
  background-color: #fff;
  margin-top: unset;
  cursor: pointer;
}

.Stages-alertAdmin .chatButton-MobileAdmin span {
  padding-right: 0.625rem;
}

.Stages-alertAdmin .ant-notification-notice-icon {
  position: unset !important;
  margin-left: 0rem;
  font-size: 1.5rem;
}

.alertNotificationAppAdmin {
  display: flex !important;
  justify-content: center !important;
  height: 3.13rem !important;
  width: 100vw !important;
  border-radius: 0 !important;
  max-width: unset !important;
  right: unset !important;
  padding: 0.875rem 1.5rem !important;
  margin-right: -1.5rem;
}

.alertNotificationAppAdmin
  .ant-notification-notice-with-icon
  .ant-notification-notice-message {
  color: #fff;
}

.alertNotificationAppAdmin .ant-notification-notice-close {
  top: 0.75rem;
}

.success {
  /* background-color: var(--primaryColor) !important; */
  background-color: #118936;
}

.warning {
  background-color: #f3bd1a !important;
}

.error {
  background-color: var(--primaryColor-error);
  height: fit-content !important;
}

@media screen and (max-width: 768px) {
  .Stages-alertAdmin .ant-notification-notice-with-icon {
    flex-direction: column;
  }

  .Stages-alertAdmin .ant-notification-notice-icon {
    margin-bottom: 0.625rem;
  }
  .Stages-alertAdmin {
    height: 10.625rem !important;
    padding: 1.25rem 1.5rem 2.063rem !important;
  }

  .Stages-alertAdmin
    .ant-notification-notice-with-icon
    .ant-notification-notice-message {
    margin-top: 0rem;
  }
  .Stages-alertAdmin .chatButton-MobileAdmin {
    margin-right: 2.063rem;
    margin-top: 1.25rem !important;
  }
  .alertNotificationAppAdmin {
    justify-content: left !important;
    padding: 0.9375rem !important ;
  }
  .alertNotificationAppWrapAdmin .ant-notification-notice-close {
    right: 0.9375rem;
  }
  .Stages-alertAdmin
    .ant-notification-notice-with-icon
    .ant-notification-notice-message {
    color: #fff;
    margin-top: 0.5rem;
    /* margin-left: 2.5rem !important; */
    font-size: 0.875rem;
    font-weight: normal;
  }
  .alertNotificationAppAdmin
    .ant-notification-notice-with-icon
    .ant-notification-notice-message {
    font-size: 0.875rem;
    padding-top: 0.1875rem;
    font-weight: normal !important;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #fff;
  }
  .alertNotificationAppWrapAdmin .ant-notification-notice-icon {
    position: absolute !important;
    margin-left: 0.1875rem !important;
    font-size: 1.25rem !important;
    line-height: 1.5rem !important;
  }
  .alertNotificationAppWrapAdmin
    .ant-notification-notice-with-icon
    .ant-notification-notice-message {
    margin-left: 2rem !important;
  }
}
