.header_container {
  display: flex;
  padding: 0.9375rem 1.25rem;
  min-height: 4.375rem;
  /* justify-content: space-between; */
  justify-content: flex-end;
  align-items: center;
  background: #077cf0;
  position: fixed;
  top: 0;
  /* left: 0; */
  left: 13.75rem;
  right: 0;
  z-index: 1000;
}

.left_header {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.right_header {
  display: flex;
  gap: 0.5rem;
  cursor: pointer;
}

.user_profile_pic {
  display: grid;
  place-items: center;
  min-width: 2.5rem;
  min-height: 2.5rem;
  border-radius: 50%;
  background: #001529;
}

.user_profile_pic > span {
  font-size: 1rem;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #fff;
}

.user_profile_info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 0.125rem;
}

.user_profile_info > .user_profile_name {
  font-size: 0.875rem;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #ffffff;
}

.user_profile_info > .user_profile_role {
  font-size: 0.75rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #ffffff;
}
.header_container_collapse{
  display: flex;
  padding: 0.9375rem 1.25rem;
  min-height: 4.375rem;
  /* justify-content: space-between; */
  justify-content: flex-end;
  align-items: center;
  background: #077cf0;
  position: fixed;
  top: 0;
  /* left: 0; */
  left: 4.375rem;
  right: 0;
  z-index: 1000;
}

/********** For Mobile View  Media Query Start **********/
@media screen and (max-width: 768px) {
  .header_container {
    padding: 0.6875rem 0.9375rem;
    min-height: 3.125rem;
    justify-content: space-between;
    left: 0;
  }
  .user_profile_info {
    display: none;
  }
  .left_header > .logo {
    max-height: 1.5625rem;
  }
  .user_profile_pic {
    min-width: 1.75rem;
    min-height: 1.75rem;
  }
}
/********** For Mobile View  Media Query End **********/
