/********** For Mobile View  Media Query Start **********/
@media screen and (max-width: 768px) {
  .MobileNavMenu_body .ant-drawer-header {
    padding: 1.25rem;
    background: rgba(17, 137, 54, 0.2);
  }

  .MobileNavMenu_body .ant-drawer-body {
    padding: 2.5rem 0rem 0rem;
    background-color: #001529;
    display: flex;
    flex-direction: column;
  }

  .MobileNavMenu_body .right_header {
    gap: 1.25rem;
  }

  .MobileNavMenu_body .user_profile_pic {
    min-width: 3.75rem;
    min-height: 3.75rem;
  }
  .MobileNavMenu_body .user_profile_pic > span {
    font-size: 1.25rem;
  }

  .MobileNavMenu_body .user_profile_info {
    display: flex;
    gap: 0.25rem;
  }

  .MobileNavMenu_body .user_profile_info > .user_profile_name {
    font-size: 1rem;
    font-weight: normal;
    color: #1e1e1e;
  }

  .MobileNavMenu_body .user_profile_info > .user_profile_role {
    color: #7e7e7e;
  }
}
/********** For Mobile View  Media Query End **********/
