.container {
  display: flex;
  flex-direction: column;
  height: auto;
  border: solid 1px #e4e4e4;
  border-radius: 4px;
  --primactColor: #e2efff;
  /* --borderColor: #e4e4e4; */
  --textColor: #444;
  --background: rgba(88, 198, 33, 0.1);
  border-bottom: none;
}
.container-1 {
  display: flex;
  flex-direction: column;
  /* height: 20rem; */
  --primactColor: #e2efff;
  --borderColor: #e4e4e4;
  --textColor: #444;
  --background: rgba(88, 198, 33, 0.1);
}

/* .container > .login-form-lpass{
gap: 24px;
} */
.login-form-lpass {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin: auto;
  width: 20rem;
  /* gap: 24px; */
}

.login-form-lpass >.new_pass_input{
  margin-bottom: 20px !important;
}

.login-form-lpass >.ant-form-item{
  margin-bottom: 23px !important;
}

.login-form-button-lpass {
  height: 2.5rem;
  font-size: 16px;
  font-weight: 600;
  width: 100%;
  border-radius: 4px;
  border-color: #118936;
  background-color: #118936;
  /* margin-bottom: 24px; */
}

.email_id_field {
  display: flex;
  align-items: center;
  justify-content: center;
}

.input_feild {
  height: 40px;
  border: 1px solid #118936;
  width: 19rem;
  border-radius: 4px;
  padding-left: 16px;
}

.head-text {
  color:  #3e3e3e;
  font-size: 18px;
  font-weight: 600;
  align-self: center;
  margin: 1.875rem 0rem 0rem 0rem;
}

.bottom-text {
  font-size: 0.875rem;
  font-weight: 500;
  align-self: center;
  /* margin:10px 0px 13px 0px; */
  color: #007cf0;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 1.25rem;
  padding-bottom: 1.125rem;

}

.form-label {
  /* font-family: Roboto; */
  font-size: 0.9rem;
  font-weight: 500;
}
/* .lpass-box-text {
  display: flex;
  justify-content: center;
  align-self: center;
  width: 20rem;
  height: 9rem;
  margin: 1rem 1rem;
  font-size: 12px;
} */

.Email_Send_text p {
  margin-top: 20px;
  font-size: 12px;
  font-weight: normal;
  color: #5e5e5e;
}
.Email_Send_text p span {
  font-weight: bold;
}

.lpass-box-text {
  /* width: 20.625rem; */
  height: 4.125rem;
  font-size: 0.875rem;
  line-height: 1.57;
  margin: 0.9375rem 1.875rem 1.25rem;
  /* padding: 15px 30px 20px; */
  text-align: center;
  color: #5e5e5e;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
}
/* .lpass-box-text2 {
  display: flex;
  justify-content: center;
  align-self: center;
  width: 20rem;
  height: 2rem;
  font-size: 12px;
  margin: 1rem 0rem 1rem -2rem;
} */

.authDivider {
  height: 1px;
  /* margin: 23px 0 0; */
  background-color: #e4e4e4;
}

.lpass2-span {
  margin-top: 1.2rem;
  align-self: center;
  width: 19.5rem;
  height: 4rem;
  border-radius: 0.5rem;
  background-color: rgba(17, 137, 54, 0.1);
  border: solid 1.5px var(--background);
  display: flex;
  gap: 12px;
  padding: 10px 0rem 0rem 0.75rem;
}
.lpass2-span-img{
  padding-bottom: 1.875rem;
}
.lpass2-span-text {
  font-size: 12px;
  line-height: 1.5rem;
  font-weight: normal;
  /* margin: 0.4rem 0rem 0rem 2.6rem; */
}
.lpass-checkbox {
  color: #76d049;
  background: #fff;
  position: absolute;
  width: 14px;
  height: 14px;
  margin: 0.6rem 0rem 0rem 1rem;
  text-align: center;
  /* margin: 1.4rem 0rem 0rem -0.7rem; */
}

.ant-form-item-explain-error {
  margin-top: 8px;
  word-spacing: 2px;
  color: #db2828;
  /* font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: normal;
  text-align: left;
  color: #db2828; */
}
.valiDateInput{
 border-color: #007cf0 !important;
}
.notvaliDateInput{
  border-color: #db2828;
}
@media screen and (max-width : 768px) {
  .container {
    display: flex;
    flex-direction: column;
    height: auto;
    --primactColor: #e2efff;
    --textColor: #444;
    --background: rgba(88, 198, 33, 0.1);
    border: none;
    /* padding-top: 24px; */
    width: auto;
  }
  .container-1 {
    display: flex;
    flex-direction: column;
    /* height: 20rem; */
    --primactColor: #e2efff;
    --textColor: #444;
    --background: rgba(88, 198, 33, 0.1);
    border: none;
    padding-top: 24px;
  }
  
}