.messaging-main {
  height: 81vh;
}

.messaging-go-back {
  display: flex;
  align-items: center;
  gap: 0.19rem;

  color: #3e3e3e;
  /* font-family: SF Pro Text; */
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  margin-bottom: 1rem;
  cursor: pointer;
}
.messaging-userprofile-container {
  display: flex;
  height: 85%;
}

.messaging-container-main {
  max-width: 55.0625rem;
  width: 100%;
  height: max-content;

  border: 1px solid #e4e4e4;
}
