@font-face {
  font-family: "SFProText";
  src: local("SFProText"),
    url(../fonts/SF-Pro-Text-Regular.otf) format("opentype");
  font-weight: normal;
}

@font-face {
  font-family: "SFProText";
  src: local("SFProText"),
    url(../fonts/SF-Pro-Text-Medium.otf) format("opentype");
  font-weight: 500;
}

@font-face {
  font-family: "SFProText";
  src: local("SFProText"), url(../fonts/SF-Pro-Text-Bold.otf) format("opentype");
  font-weight: bold;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html,
body {
  background-color: #ffffff;
  font-family: SFProText, -apple-system, BlinkMacSystemFont, sans-serif !important;
}

.page_container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.main_container {
  display: flex;
  margin-top: 4.375rem;
}

.content_wrap {
  flex: 1;
  padding: 1.25rem;
  margin-left: 13.75rem;
}

.content-wrap-collapse {
  flex: 1;
  padding: 1.25rem;
  margin-left: 4.375rem;
}

iframe {
  pointer-events: none;
}

/********** For Mobile View  Media Query Start **********/
@media screen and (max-width: 768px) {
  .main_container {
    margin-top: 3.125rem;
  }
  .content_wrap,
  .content-wrap-collapse {
    padding: 0rem;
    margin-left: 0rem;
  }
}
/********** For Mobile View  Media Query End **********/
iframe {
  pointer-events: none;
}
