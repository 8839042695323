.NavigationItem {
  display: flex;
  align-items: center;
  padding: 0.5rem 1.25rem;
  min-height: 2.375rem;
  min-width: 13.75rem;
  gap: 0.5rem;
  position: relative;
  text-decoration: none;
  cursor: pointer;
}

.NavigationItem:last-child {
  margin-top: auto;
}

.NavigationItem:hover {
  background: #ddeee2;
}

.NavigationItem:hover .navTitle {
  color: #118936;
}

.NavigationItem:last-child:hover .navTitle {
  color: #7e7e7e;
}

.navTitle {
  font-size: 0.875rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #fff;
}

.navActive {
  background: #0a2b4a;
}

/* .navActive .navTitle {
  color: #118936;
} */

.navActive::before {
  content: "";
  position: absolute;
  top: 0rem;
  left: 0rem;
  min-height: 2.375rem;
  min-width: 0.25rem;
  border-bottom-right-radius: 0.125rem;
  border-top-right-radius: 0.125rem;
  background: #007cf0;
}

.navActive:last-child .navTitle {
  color: #7e7e7e;
}

/* .navActive:last-child::before {
  display: none;
} */

.isCollapse {
  min-width: 4.375rem;
  justify-content: center;
}

.copy-rigths-main {
  display: flex;
  flex-direction: column;
  margin-top: auto;
}
.copy-rigths-main .copy-rights-content {
  font-size: 0.75rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #fff;
}

.copy-rigths-main .version {
  font-size: 0.75rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #4976a0;
}
.copy-rigths-main .versionformobil {
  font-size: 0.75rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
  padding-top: 0.625rem;
}

.MenuIcon {
  /* width: 11px;
  height:11px ; */
  font-size: 0.75rem;
  color: #fff !important;
}

.left_top_header {
  display: flex;
  flex-direction: row;
  padding-left: 1.25rem;
  align-items: center;
  margin-bottom: 2.1875rem;
  gap: 0.8125rem;
}

.left_top_header .MenuIcon {
  font-size: 1.25rem;
  cursor: pointer;
}

.left_top_header .logo {
  height: 1.4375rem;
}
.left_top_header .leftArrowIcon {
  font-size: 1.375rem;
  color: #ffffff;
}
.collaps-copy-rigths-main {
  display: none;
}
.collapse-logo {
  display: none;
}

.NavigationItemLogout {
  height: 4.375rem;
  margin-top: 0.4375rem;
  border-top: 1px solid #1b3044 !important;
  border-bottom: 1px solid #1b3044 !important;
}
/********** For Mobile View  Media Query Start **********/
@media screen and (max-width: 768px) {
  .navTitle {
    font-size: 1rem;
    color: #ffffff;
  }

  .left_top_header .logo {
    height: 1.875rem;
  }

  .left_top_header .MenuIcon {
    display: none;
  }

  .copy-rigths-main {
    display: flex;
    /* justify-content: flex-start; */
    align-items: flex-start;
    margin-left: 0.9375rem;
    color: #fff;
    margin-bottom: 2.5rem;
  }
  .copy-rigths-main .copy-rights-content {
    font-size: 0.75rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #fff;
  }
  .copy-rigths-main .cversion {
    font-size: 0.75rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: red !important;
  }
  .NavigationItemLogout {
    margin-top: 15px;
    border-top: 1px solid #1f466c !important;
    border-bottom: 1px solid #1f466c !important;
  }
}
/********** For Mobile View  Media Query End **********/
