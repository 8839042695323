.DashBoard-Profile-Container {
  max-width: 25rem;
  width: 100%;
  height: 19.0625rem;
  border: 1px solid #e4e4e4;
  background: #fff;
}

.DashBoard-Profile-Container .DashBoard-Profile-info-section {
  display: flex;

  font-size: 0.5625rem;

  font-weight: normal;

  color: #666;

  background-color: #f6f6f6;

  align-items: center;

  padding: 0.25rem 0rem 0.1875rem 0.5625rem;

  display: flex;
}

.DashBoard-Profile-Container .DashBoard-Profile-info-icon img {
  height: 0.75rem;
}

.DashBoard-Profile-Container .DashBoard-Profile-info-Text {
  padding-left: 0.25rem;
}

.DashBoard-Profile-Container .DashBoard-Profile-User-Info {
  padding: 1.25rem 0 0;
}

.DashBoard-Profile-Container .DashBoard-Profile-User-online {
  font-size: 0.5625rem;

  font-weight: normal;

  font-style: normal;

  color: #118936;

  border-radius: 0.5rem;

  border: solid 0.0313rem #118936;

  background-color: #fff;

  max-width: 3.3125rem;

  display: flex;

  align-items: center;

  justify-content: center;
}

.DashBoard-Profile-Container .DashBoard-Profile-User-offline {
  color: #9e9e9e;

  border: solid 0.0313rem #9e9e9e;
}

.DashBoard-Profile-Container .DashBoard-Profile-User-online-dot {
  width: 0.25rem;

  height: 0.25rem;

  border-radius: 50%;

  background-color: #118936;

  display: inline-block;
}

.DashBoard-Profile-Container .DashBoard-Profile-User-offline-dot {
  background-color: #9e9e9e;
}

.DashBoard-Profile-Container .DashBoard-Profile-User-online-Text {
  padding-left: 0.25rem;

  padding-top: 0.0625rem;
}

.DashBoard-Profile-Container .DashBoard-Profile-Avatar {
  width: 4.375rem;
  height: 4.375rem;
  background-color: transparent;
  border-radius: 9.375rem;
  position: absolute;
  bottom: 7.938rem;
}

.DashBoard-Profile-Container .DashBoard-Profile-Avatar .avatarBgColor {
  background-color: #007cf0;
}

.DashBoard-Profile-Container .DashBoard-Profile-Avatar-section {
  display: flex;

  justify-content: center;

  flex-direction: column;

  align-items: center;
  position: relative;
}

.usercontainer-location-citizen {
  display: flex;
  gap: 5px;
  color: #7e7e7e;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  align-items: center;
  margin-top: 0.87rem;
  /* line-height: 0.9375rem; 125% */
}

.view-dashboard-user {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 3.8rem;
  border-top: 1px solid #e4e4e4;

  color: #1e1e1e;
  font-feature-settings: "clig" off, "liga" off;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  padding: 0 0.94rem;

  margin-top: 0.688rem;

  cursor: pointer;
}

.DashBoard-Profile-Container .DashBoard-Profile-user-name {
  padding-top: 0.75rem;

  font-size: 0.875rem;

  font-weight: 500;

  font-style: normal;

  color: #3e3e3e;
}

.DashBoard-Profile-Container .DashBoard-Profile-user-Profession-Code {
  font-size: 0.5625rem;

  font-weight: bold;

  color: #777;
}

.DashBoard-Profile-Container .DashBoard-Profile-user-rating-section {
  border-radius: 0.1875rem;

  box-shadow: 0 0.0625rem 0.125rem 0 #bbb;

  background-color: #fff;

  display: flex;

  align-items: baseline;

  margin-top: 0.38rem;

  padding: 0rem 0.1875rem 0.1875rem;
}

.DashBoard-Profile-Container .DashBoard-Profile-user-rating-number {
  font-size: 0.5625rem;

  font-weight: bold;

  font-style: normal;

  color: #777;

  padding-left: 0.1875rem;
}

.DashBoard-Profile-Container .DashBoard-Profile-user-rating-star .ant-rate {
  font-size: 0.75rem;

  color: #e39435;
}

.DashBoard-Profile-Container .DashBoard-Profile-user-Button {
  padding: 0.375rem 1.25rem;

  border-radius: 0.25rem;

  border: solid 0.0313rem #000;

  background-color: #fbdf50;

  margin-top: 1.25rem;

  margin-bottom: 1.25rem;

  cursor: pointer;
}

.DashBoard-Profile-Container .DashBoard-Profile-user-Button-Completed-3DResume {
  font-size: 0.75rem;

  font-weight: bold;

  font-style: normal;

  color: #000;
}

.DashBoard-Profile-Container .DashBoard-Profile-Avatar .avatar-img4 {
  width: 4.4375rem;

  height: 4.4375rem;
}

.DashBoard-Profile-Container .DashBoard-Profile-progress-bar {
  width: 5.875rem;

  height: 5.875rem;

  border-radius: 50%;

  position: relative;

  display: flex;

  align-items: center;

  justify-content: center;
}

.DashBoard-Profile-Container .DashBoard-Profile-progress-bar2 {
  width: 5.875rem;

  height: 5.875rem;

  border-radius: 50%;

  border: 0.1875rem solid #e5e0e0;
}

.DashBoard-Profile-progress-bar-inner {
  width: 5.875rem;

  height: 5.875rem;

  border-radius: 50%;

  border: 0.1875rem solid rebeccapurple;

  display: flex;

  align-items: center;

  justify-content: center;
}

.smallcircleDashborad {
  top: 8.875rem;

  left: 15.8125rem;

  position: absolute;
  transform: translate(-50%, -50%);
}

.DashBoard-Profile-user-Mobile {
  display: contents;
}

.DashBoard-Profile-Container .DashBoard-Profile-Avatar-section-Mobile {
  display: contents;
}

@media screen and (max-width: 1000px) {
  .DashBoard-Profile-Container .DashBoard-Profile-Avatar {
    width: 3.5rem;

    height: 3.5rem;

    background-color: transparent;

    border-radius: 9.375rem rem;

    position: absolute;

    top: 8.375rem;

    left: 2rem;
  }

  .DashBoard-Profile-Container .DashBoard-Profile-Avatar-section {
    flex-direction: row;

    justify-content: flex-start;

    align-items: flex-start;
  }

  .DashBoard-Profile-user-Mobile {
    padding-left: 1.25rem;

    display: unset;
  }

  .DashBoard-Profile-Container .DashBoard-Profile-User-online-despktop {
    display: none;
  }

  .DashBoard-Profile-Container .DashBoard-Profile-user-rating-section {
    width: max-content;
  }

  .DashBoard-Profile-Container .DashBoard-Profile-User-online {
    margin-top: 0.8125rem;

    margin-left: 0.875rem;
  }

  .DashBoard-Profile-Container .DashBoard-Profile-Avatar-section-Mobile {
    display: flex;

    flex-direction: column;
  }

  .DashBoard-Profile-Container .DashBoard-Profile-Avatar .avatar-img4 {
    width: 3.5rem;

    height: 3.5rem;
  }

  .DashBoard-Profile-Container .DashBoard-Profile-user-Button {
    margin-top: 0.9375rem;

    margin-bottom: 0.9375rem;
  }

  .DashBoard-Profile-Container .DashBoard-Profile-user-name {
    padding-top: unset;
  }
}
