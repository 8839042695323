.SearchAndFilterContainer {
  width: 100%;
  padding: 1.25rem 0rem;
  display: flex;
  gap: 0.3125rem;
}

.SearchAndFilterContainer > span {
  display: grid;
  place-items: center;
  padding-left: 0.9375rem;
  font-size: 0.75rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #007cf0;
  cursor: pointer;
}

.SearchAndFilterContainer > :last-child {
  /* margin-left: auto; */
  cursor: pointer;
}

/* For Result Count And Date Filter */
.rcdFilterContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.3125rem;
}

.dateRangePickerContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.dateRangePickerContainer > .userTypeText {
  font-size: 1.125rem;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #3e3e3e;
}

.dateRangePickerBox > .default90days {
  font-size: 0.75rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #7e7e7e;
}

.dateRangePicker {
  border-bottom: solid 0.0625rem #c4c4c4;
}

.rcdFilterContainer > .userTypeCount {
  font-size: 0.875rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #7e7e7e;
}

.addEmployerButton {
  font-size: 0.75rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  text-align: left;
  color: #fff;
  padding: 0.3125rem;
  border-radius: 0.25rem;
  border: solid 0.0625rem #0f1e2b;
  background-color: #0f1e2b;
  cursor: pointer;
}

/* For Search Filter */

.SearchFilterContainer {
  position: relative;
  min-width: 16.25rem;
}

.SearchFilterContainer > .searchInput {
  width: 100%;
  padding: 0.625rem 2.375rem;
  font-size: 0.75rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #1e1e1e;
  outline: none;
  border-radius: 0.25rem;
  border: solid 0.0625rem #e4e4e4;
  background-color: #fff;
}

.SearchFilterContainer > .hideIconInput {
  padding: 0.625rem 0.9375rem;
}

.SearchFilterContainer > .searchIcon {
  position: absolute;
  top: 0.4688rem;
  left: 0.75rem;
}

.SearchFilterContainer > .closeIcon {
  position: absolute;
  top: 0.625rem;
  right: 0.75rem;
  width: 1rem;
  height: 1rem;
  cursor: pointer;
}

/* For Search Suggestion */

.suggestionDropdownConatainer {
  position: absolute;
  top: 2.5rem;
  left: 0rem;
  width: 100%;
  padding: 0.9375rem 0rem;
  border-radius: 0.25rem;
  border: solid 0.0625rem #e4e4e4;
  background-color: #fff;
  z-index: 2;
}

.suggestionType {
  margin-bottom: 0.4375rem;
  padding: 0rem 0.9375rem;
  font-size: 0.75rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #5e5e5e;
}

.suggestionList {
  display: flex;
  flex-direction: column;
  gap: 0.4375rem;
}

.suggestionListItem {
  padding: 0rem 0.9375rem;
  font-size: 0.75rem;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #1e1e1e;
  cursor: pointer;
}

.mtop7px {
  margin-top: 0.4375rem;
}

/* For Dropdown Filter */

.DropdownFilterContainer {
  position: relative;
  min-width: 8.125rem;
}

.selectedDropdownFilter {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.625rem 0.75rem;
  cursor: pointer;
}

.selectedDropdownFilter > span {
  max-width: 6.25rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #5e5e5e;
}

.CampaignsSearch {
  padding: 0.625rem 0.9375rem;
}

.CampaignsSearch input {
  width: 100%;
  padding: 0.5rem 0.625rem;
  font-size: 0.75rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #1e1e1e;
  outline: none;
  border-radius: 0.25rem;
  border: solid 0.0625rem #e4e4e4;
  background-color: #fff;
}

.CampaignsSearch
  .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover,
.CampaignsSearch .ant-input-affix-wrapper:focus,
.CampaignsSearch .ant-input-affix-wrapper-focused {
  box-shadow: none;
  border-radius: 0.25rem;
  border: solid 0.0625rem #e4e4e4;
  background-color: #fff;
}

.optionDropdownFilter {
  width: max-content;
  max-width: 21.25rem;
  min-width: 8.125rem;
  position: absolute;
  top: 2.5rem;
  left: 0rem;
  display: flex;
  flex-direction: column;
  z-index: 3;
}

.optionDropdownFilter > span {
  display: flex;
  align-items: center;
  padding: 0.625rem 0.9375rem;
  font-size: 0.75rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #3e3e3e;
  cursor: pointer;
}

.optionDropdownFilter > span > .campaignTitle {
  display: inline-block;
  max-width: 17.8125rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.ml-4{
  margin-left:4px;
}

.filterCount {
  color: #8e8e8e;
}

.optionDropdownFilter > span:hover {
  background: #efefef;
}

.selectedDropdownFilter,
.optionDropdownFilter {
  border-radius: 0.25rem;
  border: solid 0.0625rem #e4e4e4;
  background-color: #fff;
}
