.UserCell {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  cursor: pointer;
}

.UserCell .UserPicName > img {
  width: 1.375rem;
  height: 1.375rem;
  border-radius: 50%;
}

.UserCell .UserPicName {
  display: flex;
  gap: 0.3125rem;
  align-items: center;
}

.UserCell .UserPicName > span.UserChar {
  display: grid;
  place-items: center;
  font-size: 0.75rem;
  width: 1.375rem;
  height: 1.375rem;
  border-radius: 50%;
  color: #fff;
  background: #007cf0;
}

.UserPicName .UserFullName,
.CompanyCell {
  font-size: 0.75rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #1e1e1e;
  cursor: pointer;
}

.UserPicName .UserFullName {
  max-width: 11.25rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 600;
  color: #000;
}

.UserCell .UserOtherInfo {
  display: flex;
  gap: 0.5rem;
  flex-wrap: wrap;
}

.UserCell .LastLoginInfo {
  display: flex;
  gap: 0.3125rem;
  align-items: center;
}

.UserCell .LastLoginInfo .UserNonActive {
  margin: auto;
  width: 0.25rem;
  height: 0.25rem;
  border-radius: 50%;
  background: #979797;
}

.UserCell .LastLoginInfo .UserIsActive {
  background: #2ba852;
}

.UserCell .LastLoginInfo .LastLoginTime {
  font-size: 0.75rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #979797;
}

.UserOtherInfo .UserOtherInfoInside {
  display: flex;
  gap: 0.3125rem;
  align-items: center;
}

.UserOtherInfo .UserOtherInfoInside > span {
  font-size: 0.75rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #7e7e7e;
}

.messaging-icon .ant-badge-dot {
  width: .625rem;
  height: .625rem;
  min-width: .625rem;
}

.messaging-icon-cell-grey {
  border-color: #7E7E7E !important;
}

.Talent-conversationExists {
  display: none !important;
}

.UserCell .Resume {
  display: flex;
  gap: 0.3125rem;
  align-items: center;
}

.UserCell .Resume > span {
  font-size: 0.75rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #007cf0;
}

.ProfCell {
  font-size: 0.75rem;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #3e3e3e;
  cursor: pointer;
}

.StatusCell {
  display: flex;
  flex-direction: column;
  gap: 0.3125rem;
  cursor: pointer;
}

.StatusCell > .Status > span {
  padding: 0.1875rem 0.5rem;
  border-radius: 0.25rem;
  font-size: 0.6875rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.17;
  letter-spacing: normal;
  text-align: left;
}

.StatusCell > .Status > span.StatusActive {
  color: #118936;
  background-color: #e3f1e7;
}

.StatusCell > .Status > span.StatusInactive,
.StatusCell > .Status > span.StatusDeactivated,
.StatusCell > .Status > span.StatusDeleted {
  color: #db2828;
  background-color: #fceaea;
}

.StatusCell > .Status > span.StatusPending {
  color: #7e7e7e;
  background-color: #e4e4e4;
}

.StatusCell .CreadtedTime {
  font-size: 0.6875rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #7e7e7e;
}

.starIcon {
  font-size: 0.75rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #ffba08;
}

.Rating {
  font-size: 0.75rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #ffba08;
}

.Review {
  font-size: 0.75rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #7e7e7e;
}

.InfoCell {
  display: flex;
  flex-direction: column;
  cursor: pointer;
}

.InfoCell > span {
  font-size: 0.75rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: normal;
  text-align: left;
  color: #7e7e7e;
}

.InfoCell .infoBold {
  color: #1e1e1e;
}

.CursorShow {
  cursor: pointer;
}

.messaging-icon-cell {
  display: flex;
  /* justify-content: center; */
  align-items: center;
  cursor: pointer;
}

.messaging-icon {
  display: flex;
  justify-content: center;
  align-items: center;

  width: 2.5rem;
  height: 2.5rem;

  border-radius: 0.25rem;
  border: 1px solid #007cf0;
  background: #fff;
}

.Private-cantiner .PrivatePicName {
  display: flex;
  align-items: center;
  gap: .4375rem;
}

.Private-cantiner {
  display: flex;
  gap: 5px;
  flex-direction: column;
}

.PrivatePicName-Pvt-Name {
  font-size: 12px;
  color: #3e3e3e;
  font-style: normal;
  font-weight: normal;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.PrivatePicName .ant-avatar>img {
  object-fit: scale-down;
}

.PrivatePicName .ant-avatar-circle {
  border: solid .0625rem #dddada;
  background-color: black;
}

@media screen and (max-width: 768px) {
  .Private-cantiner {
    flex-direction: row
  }
}
