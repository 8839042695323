.side_navigation_container {
  display: flex;
  grid-gap: 0.5rem;
  gap: 0.5rem;
  flex-direction: column;
  padding: 1.4375rem 0rem 0.75rem;
  /* height: calc(100vh - 4.375rem); */
  box-shadow: 0 0 0.25rem 0 rgba(0, 0, 0, 0.25);
  background-color: #001529;
  margin-top: -4.375rem;
  height: 100vh;
  position: fixed;
}

/********** For Mobile View  Media Query Start **********/
@media screen and (max-width: 768px) {
  .side_navigation_container {
    display: none;
  }
}
/********** For Mobile View  Media Query End **********/
