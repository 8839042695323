/* Make the modal mask (background overlay) transparent */


.custom-modal .ant-modal-mask {
    background-color: transparent !important;
  }
  .ant-modal-body{
    padding: 0;
    margin: 0;
  }
  .ant-notification-topLeft .ant-notification-notice, .ant-notification-bottomLeft .ant-notification-notice{
    top: -12px;
    left: 60%;
    width: 600px;
    border-radius: 5px;
    height: 50px;
  }

  .ant-modal-header{
    padding: 16px 24px 16px 10px;
  }
  .Modal-Container{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 5px;
    cursor: pointer;
    background-color: #ffffff; 
    color: #3E3E3E; 
    width: 100%;
    height: 100%;
    margin: 0;
  }
  .sub-container{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 95%;
  }

  .pvtModalPic{
    margin-bottom: 10px;  
    object-fit: scale-down;
    background-color: black;
    border-radius: 50%; 
    width: 26px;
    height: 26px;
  }

  .custom-header{
  padding: 14px;
  background-color: white;
  border-bottom: 1px solid #e8e8e8;
  cursor: pointer;
  font-size: 16px;
  }
  .PvtModalName{
    margin:0 0 10px 5px;
    padding-top: 4px;
    font-size: 12px;
  }
  
  .Leave-btn{
    width: 173px;
    height: 35px;
    border-radius: 4px;
    border: 1px solid #7E7E7E;
    background-color: white;
    color: #7E7E7E;
    font-size: 11px;
    font-weight: 400;
    margin-bottom: 10px;
    margin-top: 5px;
    
  }
  .ant-avatar > img{
    object-fit: scale-down;
  }
  .sub-container:hover{
    color: #000000;
    background-color: #ededed; 
    font-weight: 700;
  }
  .Modal-Container:hover {
    color: #000000;
    background-color: #ededed; 
    font-weight: 700;
  }
  .pvtModalPic:hover {
    background-color: black;
    border-radius: 50%;
    
  }
  li {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }
  
 .namePicWrapper{
  display: flex;
 }
  
  button {
    margin-left: auto;
  }
  
  .ant-modal-content {
    padding: 0;
}
.RemovedText{
  margin-bottom: 0;
  color:#3E3E3E ;
  margin-left:10px ;
  font-size:16px ;
}
.messageCon{
  display: flex;
  justify-content: left;
  align-items: center;
}

@media (max-width: 600px) {

  .ant-notification-notice{
    max-width: 100vw !important;
  }

  .ant-notification-topLeft, .ant-notification-bottomLeft{
    margin-left: 0px !important;
    margin-top: -1px;
  }
  .ant-notification-topLeft .ant-notification-notice, .ant-notification-bottomLeft .ant-notification-notice {
    margin-right: 0px !important;
    margin-left: 0 !important;
  }
  .sub-container {
    flex-direction: column; 
  }
  .namePicWrapper{
    width: 100%;
    margin-top: 10px;
    display: flex;
    margin-bottom: -2px;
    
  }
  .btnWrapper{
    width: 100%;
  }
  .Leave-btn{
    width: 100%;
  }
  .ant-notification-topLeft .ant-notification-notice, .ant-notification-bottomLeft .ant-notification-notice{
    top: -23px;
    left: 0%;
    width: 500px;
    margin: 0 5px 0 5px;
  }
  .RemovedText{
     font-size: 14px;
     line-height: normal;
     margin-top: -7px;
     margin-left: 0px;
  }
  .messageCon{
    display: flex;
    gap: 10px;
    margin-left: -10px;
    height: 40px;
    margin-top: -8px;
  }
  .tikImage{
    margin-bottom: 2px;
  }
}


