.CommonPaginationContainer {
  display: flex;
  justify-content: space-between;
  min-height: 13.75rem;
  margin-top: 1.875rem;
}

/* .leftPaginationItem {
  margin-left: 0.625rem;
} */

.rightPaginationItem {
  margin-right: 1.1875rem;
}

.PerPageContainer {
  position: relative;
}

.PagesContainer {
  display: flex;
  align-items: center;
  gap: 1.125rem;
}

.PagesContainer > svg {
  cursor: pointer;
}

.pagesItem {
  display: flex;
  align-items: center;
  gap: 0.75rem;
}

.pagesItem > span {
  font-size: 0.875rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: rgba(30, 30, 30, 0.85);
}

.pageActive {
  padding: 0.0625rem 1.25rem;
  border-radius: 0.125rem;
  border: solid 0.0625rem #d4d4d4;
  background-color: #fff;
}

.selectedPerPage {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 2.5rem;
  padding: 0.625rem;
  cursor: pointer;
}

.selectedPerPage > span {
  font-size: 0.75rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #4e4e4e;
}

.optionPerPage {
  position: absolute;
  top: 2.8125rem;
  left: 0rem;
  display: flex;
  flex-direction: column;
  gap: 0.3125rem;
}

.optionPerPage > span {
  padding: 0.3125rem 0.625rem;
  font-size: 0.75rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #3e3e3e;
  cursor: pointer;
}

.optionPerPage > span:hover {
  background: #e4e4e4;
}

.selectedPerPage,
.optionPerPage {
  width: 100%;
  border-radius: 0.25rem;
  border: solid 0.0625rem #e4e4e4;
  background-color: #fff;
}

svg.disableButton {
  cursor: not-allowed;
}

.leftPaginationItem .ant-pagination {
  color: rgba(30, 30, 30, 0.85);
}

.leftPaginationItem .ant-pagination-simple .ant-pagination-simple-pager input {
  border-radius: 0.125rem;
  border: solid 0.0625rem #d4d4d4;
  background-color: #fff;
}

.leftPaginationItem
  .ant-pagination-simple
  .ant-pagination-simple-pager
  input:hover,
.leftPaginationItem
  .ant-pagination-simple
  .ant-pagination-simple-pager
  input:focus {
  border-color: #4054b2;
}
